import React, { useState, useEffect } from "react";
import axios from "axios";
import "./collaboration.css";
import { Helmet } from "react-helmet";

const Collaboration = () => {
  const [state, setState] = useState({});
  const [showData] = useState(false);

  const getAllDataStateWise = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/count/userStateWise`
      );
      const data = response?.data?.data;
      setState(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDataStateWise();
  }, []);

  // Calculate totals for each column
  const calculateTotals = () => {
    let totalInstitutes = 0;
    let totalStudents = 0;
    let totalEducators = 0;

    Object.values(state).forEach((stateData) => {
      totalInstitutes += stateData?.Institute || 0;
      totalStudents += stateData?.Student || 0;
      totalEducators += stateData?.Educator || 0;
    });

    return { totalInstitutes, totalStudents, totalEducators };
  };

  const { totalInstitutes, totalStudents, totalEducators } = calculateTotals();

  return (
    <div className="main-wrapper">
      <Helmet>
        <title>
          Collaboration - Schools, Students, and Educators Across India
        </title>
        <meta
          name="description"
          content="Explore the network of schools, students, and special educators registered with edeekshaam across various Indian states. Join us in fostering inclusive education."
        />
        <link rel="canonical" href="https://www.edeekshaam.in/collaboration" />
      </Helmet>
      <div className="page-banner mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="mb-0">Collaboration</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 justify-content-center">
        <div className="col-xl-9 col-lg-8 col-md-12">
          <div className="row m-0 p-0">
            <div className="col-md-12">
              <div
                className="settings-widget"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px" }}
              >
                <div className="sell-course-head comman-space">
                  <h3>We have collaborated with different schools and NGOs.</h3>
                  <p>
                    The project team is thankful to the schools, NGOs, and
                    professionals who have collaborated with us. The list of
                    collaborators is continually expanding, but we would like to
                    acknowledge those who have already been associated with us
                    here.
                  </p>
                </div>
                <div className="comman-space pb-0">
                  <div className="settings-tickets-blk course-instruct-blk table-responsive">
                    <table className="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>States</th>
                          <th className="text-center">Institutions</th>
                          <th className="text-center">Students</th>
                          <th className="text-center">Educators</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      {Object.entries(state).map(([stateName, stateData]) => (
                        <tbody key={stateData}>
                          {!showData && (
                            <tr>
                              <td className="instruct-orders-info text-capitalize">
                                <p>{stateName}</p>
                              </td>
                              <td className="text-center">
                                {stateData?.Institute || 0}
                              </td>
                              <td className="text-center">
                                {stateData?.Student || 0}
                              </td>
                              <td className="text-center">
                                {stateData?.Educator || 0}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      ))}
                      <tr>
                        <td
                          className="font-weight-bold pb-3 pt-2"
                          style={{ paddingLeft: "7px", fontWeight: "bold" }}
                        >
                          Total
                        </td>
                        <td
                          className="text-center font-weight-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalInstitutes}
                        </td>
                        <td
                          className="text-center font-weight-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalStudents}
                        </td>
                        <td
                          className="text-center font-weight-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalEducators}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collaboration;
