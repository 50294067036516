import React, { useState } from "react";
import PropTypes from "prop-types";

function SitemapItem({ item }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      {item.children ? (
        <>
          <span onClick={toggleExpand} style={{ cursor: "pointer" }}>
            {isExpanded ? "🔽 " : "▶️ "}
          </span>
          <strong>{item.title}</strong>
          {isExpanded && (
            <div>
              {item.children.map((child, index) => (
                <SitemapItem key={index} item={child} />
              ))}
            </div>
          )}
        </>
      ) : (
        <div>
          <a href={item.link}>{item.title}</a>
        </div>
      )}
    </div>
  );
}

SitemapItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string,
        children: PropTypes.array,
      })
    ),
  }).isRequired,
};

function Sitemap() {
  const sitemapData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About Us",
      children: [
        { title: "Edeekshaam", link: "about-edeeksham", target: "_blank" },
        { title: "MANUU", link: "about-manuu" },
      ],
    },
    {
      title: "Events",
      children: [
        { title: "Workshops", link: "workshop" },
        { title: "Other Events", link: "other-events" },
      ],
    },
    {
      title: "Collaboration",
      link: "collaboration",
    },
    {
      title: "Important Links",
      link: "important-links",
    },
    {
      title: "Contact Us",
      link: "contact-us",
    },
    {
      title: "Registration",
      children: [
        {
          title: "Institute",
          link: "https://dashboard.edeekshaam.in/institue-register",
        },
        {
          title: "Educator",
          link: "https://dashboard.edeekshaam.in/educator-register",
        },
        {
          title: "Parent",
          link: "https://dashboard.edeekshaam.in/parent-register",
        },
      ],
    },
    {
      title: "User Login",
      children: [
        {
          title: "Login:Institute Dashboard",
          link: "https://dashboard.edeekshaam.in/login",
          children: [
            {
              title: "Dashboard",
            },
            {
              title: "Profile",
            },
            {
              title: "Verification Center",
              children: [
                {
                  title: "New Educator",
                },
              ],
            },
            {
              title: "Educator",
            },
            {
              title: "Students",
            },
            {
              title: "Learning Contents",
              children: [
                {
                  title: "Domains",
                },
              ],
            },
            {
              title: "AR/VR Contents",
            },
            {
              title: "Academic Year",
            },
            {
              title: "Logout",
            },
          ],
        },
        {
          title: "Login:Educator Dashboard",
          link: "https://dashboard.edeekshaam.in/login",
          children: [
            {
              title: "Dashboard",
            },
            {
              title: "Profile",
            },
            {
              title: "Students",
            },
            {
              title: "Learning Contents",
              children: [
                {
                  title: "Domains",
                },
              ],
            },
            {
              title: "AR/VR Contents",
            },
            {
              title: "Logout",
            },
          ],
        },
        {
          title: "Login:Parent Dashboard",
          link: "https://dashboard.edeekshaam.in/login",
          children: [
            {
              title: "Dashboard",
            },
            {
              title: "Profile",
            },
            {
              title: "Childs",
            },
            {
              title: "Learning Contents",
              children: [
                {
                  title: "Domains",
                },
              ],
            },
            {
              title: "AR/VR Contents",
            },
            {
              title: "Logout",
            },
          ],
        },
      ],
    },
    {
      title: "Student Login",
      children: [
        {
          title: "Login:Student Dashboard",
          children: [
            {
              title: "Dashboard",
            },
            {
              title: "Profile View",
              children: [
                {
                  title: "See Profile",
                },
                {
                  title: "Logout",
                },
              ],
            },
            {
              title: "Case History",
            },
            {
              title: "Baseline Assessment",
            },
            {
              title: "Academic Year",
            },
            {
              title: "Goals",
              children: [
                {
                  title: "Probable Goals",
                },
                {
                  title: "Selected Goals",
                },
                {
                  title: "Long Term Goals",
                },
                {
                  title: "Short Term Goals",
                },
              ],
            },
            {
              title: "Take Class",
            },
            {
              title: "Evaluation",
            },
            {
              title: "Behaviour",
              children: [
                {
                  title: "Statistics",
                },
                {
                  title: "History",
                },
              ],
            },
            {
              title: "Performance",
              children: [
                {
                  title: "Chart",
                },
              ],
            },
            {
              title: "AR/VR Contents",
            },
            {
              title: "Daily Activities",
            },
          ],
        },
      ],
    },
    {
      title: "Child Login",
      children: [
        {
          title: "Login:Child Dashboard",
          children: [
            {
              title: "Dashboard",
            },
            {
              title: "Profile View",
              children: [
                {
                  title: "See Profile",
                },
                {
                  title: "Logout",
                },
              ],
            },
            {
              title: "Case History",
            },

            {
              title: "Behaviour",
              children: [
                {
                  title: "Statistics",
                },
                {
                  title: "History",
                },
              ],
            },
            {
              title: "AR/VR Contents",
            },
            {
              title: "Daily Activities",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <div className="page-banner mb-2 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="mb-0">Sitemap</h1>
            </div>
          </div>
        </div>
      </div>
      {sitemapData.map((item, index) => (
        <SitemapItem key={index} item={item} />
      ))}
    </div>
  );
}

export default Sitemap;
