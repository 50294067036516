import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import siteMap from "../../assets/img/Edeekshaam_WebSite_Tree.drawio.svg";


const WebsiteSitemap = () => {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    fetch(siteMap)
      .then((response) => response.text())
      .then((text) => setSvgContent(text))
      .catch((error) => console.error("Error loading SVG:", error));
  }, []);

  return (
    <div className="main-wrapper">
      <Helmet>
        <title>Sitemap - Edeekshaam</title>
        <meta
          name="description"
          content="Explore the Edeekshaam website for innovative educational resources, personalized learning experiences, and a vast collection of tools to help you achieve academic success."
        />
        <link rel="canonical" href="https://www.edeekshaam.in/sitemap" />
      </Helmet>

      <div className="row m-0 p-0 justify-content-center">
        <div
          className="responsive-svg-container"
          dangerouslySetInnerHTML={{ __html: svgContent }}
        />
      </div>

      <style>
        {`
          .responsive-svg-container {
            width: 100%;
            max-width:2800px;
            height: auto;
            margin-top: 25px;
            margin-bottom: 25px;
          }
          .responsive-svg-container svg {
            width: 100%;
            height: auto;
          }
        `}
      </style>
    </div>
  );
};

export default WebsiteSitemap;
